'use client'; // Error components must be Client Components

import {
    Button,
    CustomErrorContent,
    CustomErrorFooter,
    CustomErrorHeader,
    CustomErrorRoot,
    Heading,
    Text,
} from '@i2e/components';
import Image from 'next/image';
import Link from 'next/link';

export const SUPPORT_URL = 'mailto:support@in2event.com';

const Error = ({ reset }: { error: Error & { digest?: string }; reset: () => void }) => {
    return (
        <CustomErrorRoot>
            <CustomErrorHeader>
                <Image
                    src="/images/logo.png"
                    alt="logo"
                    width={64}
                    height={64}
                    className="rounded-full"
                />
            </CustomErrorHeader>

            <CustomErrorContent>
                <Heading>Something went wrong!</Heading>
                <Text className="whitespace-pre-line">
                    Server is not responding. Please, try again later. We are sorry for the
                    inconvenience.
                </Text>
            </CustomErrorContent>

            <CustomErrorFooter>
                <Button
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => reset()
                    }
                >
                    Try Again
                </Button>
                <Button variant="primary" asChild>
                    <Link href={SUPPORT_URL}>Contact Support</Link>
                </Button>
            </CustomErrorFooter>
        </CustomErrorRoot>
    );
};

export default Error;
